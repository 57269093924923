<template>
  <div class="container">
    <div class="form_container" v-if="!verificationStep">
      <img src="../assets/logo.png" alt="TKL" class="form_logo" />
      <form class="form">
        <input
          type="text"
          class="form_input"
          :placeholder="$t('common.firstname')"
          name="First Name"
          v-model="firstName"
          autocomplete="given-name"
          required
          autofocus
        />
        <input
          type="text"
          class="form_input"
          :placeholder="$t('common.lastname')"
          name="Last Name"
          v-model="lastName"
          autocomplete="family-name"
          required
          autofocus
        />
        <input
          type="text"
          class="form_input"
          :placeholder="$t('common.email')"
          name="Email"
          v-model="email"
          autocomplete="email"
          required
          autofocus
        />
        <input
          type="text"
          class="form_input"
          :placeholder="$t('common.phone_number')"
          name="Phone Number"
          v-maska="'(###)-###-####'"
          v-model="phoneNumber"
          autocomplete="tel"
          v-if="registrationForm.Phone"
          required
          autofocus
        />
        <country-select
          v-model="Nationality"
          :country="Nationality"
          :placeholder="$t('common.nationality')"
          topCountry="CA"
          class="form_input form_select"
          v-if="registrationForm.Nationality"
        />
        <div class="form_input">
          <label for="dateOfBirth">{{ $t('common.date_of_birth') }}</label>
          <input type="date" id="dateOfBirth" name="dateOfBirth" v-model="BirthDate">
        </div>
        <input
          type="text"
          class="form_input"
          :placeholder="$t('common.postal_code')"
          name="Postal Code"
          v-model="PostalCode"
          v-maska="'XXXXXX'"
          v-if="registrationForm.PostalCode"
          required
          autofocus
        />
        <div class="form_selector" v-if="this.registrationForm.Sex">
        <label for="M" style="display: inline-block; margin:auto;">
          M
          <input
            type="radio"
            value="M"
            v-model="Sex"
            style="margin:0px; width: 50px; height:15px; margin-left:10px;"
            class="form_input"
            name="S"
            required
          />
        </label>
        <label for="F" style="display: inline-block; margin:auto;">
          F
          <input
            type="radio"
            value="F"
            v-model="Sex"
            style="margin:0px; width: 50px; height:15px; margin-left:10px;"
            class="form_input"
            name="S"
            required
          />
        </label>
      </div>
        <input
          type="password"
          class="form_input"
          :placeholder="$t('common.password')"
          name="Password"
          v-model="pass"
          autocomplete="new-password"
          required
          autofocus
        />
        <input
          type="password"
          class="form_input"
          :placeholder="$t('common.confirm_password')"
          name="Password Confirmation"
          v-model="passConfirmation"
          autocomplete="new-password"
          required
          autofocus
        />
      </form>
      <button class="form_btn" @click="register">{{ $t('common.register') }}</button>
      <google-auth-sign-in class="google-signin"/>
      <p class="form_error"
        v-for="(error, id) in errors"
        v-bind:key="id"
      >
        {{ $t(`registration.errors.${error}`) }}
      </p>
    </div>
    <AccountVerification :isRegister="true" v-else/>
  </div>
</template>

<!-- Script for the menu -->

<script>
import axios from "axios";
import AccountVerification from "@/components/AccountVerification.vue";
var countries = require("i18n-iso-countries");
import { mapState } from "vuex";
export default {
  props: {
    isComponent: {
      type: Boolean,
      default: false,
    },
    showRegistrationModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AccountVerification,
  },
  computed: {
    ...mapState(["isLoggedIn","user"]),
    verified() {
      return this.user && this.user.Verified;
    },
  },
  data() {
    return {
      Nationality: null,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      pass: "",
      passConfirmation: "",
      Sex: "",
      errors: [],
      PostalCode: "",
      BirthDate: "",
      registrationForm: {
        Phone: null,
        Nationality: null,
        BirthDate: null,
        PostalCode: null,
        Sex: null,
      },
      verificationStep: false,
    };
  },
  created() {
    this.loadConfig();
  },
  methods: {
    async loadConfig() {
      let res = await axios.get(this.$apiUrl + "/config/");
      this.registrationForm = res.data.RegistrationForm;
    },
    register() {
      this.errors = [];
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.pass.length < 8) {
        this.errors.push("pass_length");
      }
      if (this.pass != this.passConfirmation) {
        this.errors.push("pass_match");
      }

      if (!re.test(this.email) || this.email.length == 0) {
        this.errors.push("email");
      }
      if (this.firstName.length == 0) {
        this.errors.push("first_name");
      }
      if (this.lastName.length == 0) {
        this.errors.push("last_name");
      }
      if (this.registrationForm.Nationality && this.Nationality) {
        this.Nationality = countries
          .alpha2ToAlpha3(this.Nationality)
          .toLowerCase();
      }

      if (this.errors.length == 0) {
        axios
          .post(this.$apiUrl + "/auth/register", {
            Email: this.email,
            password: this.pass,
            First_Name: this.firstName,
            Last_Name: this.lastName,
            BirthDate: this.BirthDate,
            Phone: this.phoneNumber,
            Nationality: this.Nationality,
            PostalCode: this.PostalCode,
            Sex: this.Sex,
            Account_Type: "Player",
          })
          .then((res) => {
            this.$store.dispatch('login', res.data.token)
            this.authenticateUser();
          })
          .catch((err) => {
            this.errors.push(err.response.data);
          });
      }
    },
    authenticateUser(){
      this.clearForm();
      if(!this.verified){
        this.verificationStep = true;
        return;
      } 
      else if(!this.isComponent) {
        this.$router.push({ name: `${this.verificationStep ? 'verification' : 'home'}` });
      }
      this.verificationStep = false;  
    },
    clearForm(){
      this.errors = [];
      this.email = "";
      this.pass = "";
      this.passConfirmation = "";
      this.firstName = "";
      this.lastName = "";
      this.phoneNumber = "";
      this.Nationality = "";
      this.PostalCode = "";
      this.BirthDate = "";
      this.Sex = "";
    },
  },
  watch: {
    showRegistrationModal(val) {
      if(!val){
        this.verificationStep = false;
      }
    },
    isLoggedIn(val) {
      if(val){
        this.authenticateUser();
      }
    },
    verified(val) {
      if(val){
        this.authenticateUser();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/theme.scss";

.container {
  width: 40vw;
  min-width: 500px;
}

.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form_input {
  border: 0;
  padding: 7px;
  border-bottom: 1px solid #ccc;
  width: 30vw;
  height: 20px;
  margin-top: 15px;

  #dateOfBirth {
    border: 1px solid #ccc;
  }

  label {
    color: #666;
    margin-right: 10px;
  }
}

.form_logo {
  margin-top: 5%;
  width: 20vw;
}

.form_btn{
  @extend %secondary-pattern;
  cursor: pointer;
  display: block;
  padding: 10px;
  width: 30vw;
  margin-top: 30px;
  border: 0px solid black;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px;
  font-size: 18px;
  color: white;
}

.form_selector {
  margin-top: 10px;
  display: flex;
  width: 30vw;
}

.form_select {
  height: 45px;
}

.form_error {
  margin-top: 10px;
  text-align:center; 
  color:red; 
  font-weight:bold;
}

.google-signin {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {

  .container {
    width: 80vw;
    min-width: initial;
  }

  .form_input {
    width: 60vw;
    font-size: 14px;
  }

  .form_logo {
    margin-top: 5%;
    width: 50vw;
  }

  .form_btn {
    width: 60vw;
    font-size: 14px;
  }

  .form_selector {
    width: 60vw;
  }

  .form_error {
    font-size: 14px;
  }
}
</style>
